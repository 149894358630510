import { useQueryClient } from '@tanstack/vue-query'
import { reportTemplatesOptions } from '@reports/composables'
import type { Product } from '@reports'

export default defineNuxtRouteMiddleware(async ({ path, params, query }) => {
  const divisionId = params.companyId as string
  const templateId = query.template as string | undefined
  const product = query.product as Product | undefined

  // If it's a custom template and product is defined,
  // then we don't need to check if the template exists.
  if (templateId === 'custom' && product) return

  // Otherwise, check if the template exists.
  try {
    const queryClient = useQueryClient()
    const templates = await queryClient.ensureQueryData(
      reportTemplatesOptions(divisionId),
    )

    if (!templateId || !templates.some((t) => t.id === templateId))
      throw new Error('Template not found')
  } catch (e) {
    return navigateTo(`/division/${divisionId}/reports`)
  }
})
